import { createRouter, createWebHistory } from 'vue-router'
import i18n from '@/i18n'

const Home = () => import('../views/Home.vue')
const Projects = () => import('../views/Projects.vue')
const Resume = () => import('../views/Resume.vue')
const CheatSheet = () => import('../views/CheatSheet.vue')
const Contact = () => import('../views/Contact.vue')

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/:lang',
			name: 'home',
			component: Home,
			meta: {
				next: 'projects',
				previous: 'contact',
			},
		},
		{
			path: '/:lang/projects',
			name: 'projects',
			component: Projects,
			meta: {
				next: 'resume',
				previous: 'home',
			},
		},
		{
			path: '/:lang/resume',
			name: 'resume',
			component: Resume,
			meta: {
				next: 'cheatsheet',
				previous: 'projects',
			},
		},
		{
			path: '/:lang/cheatsheet',
			name: 'cheatsheet',
			component: CheatSheet,
			meta: {
				next: 'contact',
				previous: 'resume',
			},
		},
		{
			path: '/:lang/contact',
			name: 'contact',
			component: Contact,
			meta: {
				next: 'home',
				previous: 'cheatsheet',
			},
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: 'home',
		},
	],
})

const updateMeta = (routeName, fullPath, lang) => {
	document.querySelector('html').setAttribute('lang', lang)
	// title
	document.title = i18n.global.t(`${routeName}.tagTitle`)
	document.querySelector('meta[property="og:title"]').content = i18n.global.t(
		`${routeName}.tagTitle`
	)

	// description
	document.getElementsByTagName('meta')['description'].content = i18n.global.t(
		`${routeName}.metaDescription`
	)
	document.querySelector('meta[property="og:description"]').content =
		i18n.global.t(`${routeName}.metaDescription`)

	// og:type
	document.querySelector('meta[property="og:type"]').content = i18n.global.t(
		`${routeName}.metaOgType`
	)

	// urls
	let hreflangFr = window.location.origin + '/fr'
	let hreflangEn = window.location.origin + '/en'
	if (routeName !== 'home') {
		hreflangFr += '/' + routeName
		hreflangEn += '/' + routeName
	}
	document.getElementById('hreflangFr').href = hreflangFr
	document.getElementById('hreflangEn').href = hreflangEn

	// og:url
	document.querySelector('meta[property="og:url"]').content =
		window.location.origin + fullPath
}

const locales = Object.keys(i18n.global.messages)

router.beforeEach((to, from, next) => {
	const lang = to.params.lang
	if (!locales.includes(lang)) {
		return next('fr')
	}
	if (i18n.global.locale !== lang) {
		i18n.global.locale = lang
	}

	updateMeta(to.name, to.fullPath, lang)

	return next()
})

export default router
