import telusLogoUrl from '@/assets/images/projects/telus_logo.png'
import actusiteLogoUrl from '@/assets/images/projects/actusite_logo.svg'
import vigilanceLogoUrl from '@/assets/images/projects/vigilance_logo.svg'
import pomeloLogoUrl from '@/assets/images/projects/pomelo_logo.png'
import fivesLogoUrl from '@/assets/images/projects/fives_logo.png'
import fivesPointageUrl from '@/assets/images/projects/fives_pointage.png'
import fivesPdpUrl from '@/assets/images/projects/fives_pdp.png'
import fivesPdp21Url from '@/assets/images/projects/fives_pdp2-1.png'
import fivesPdp22Url from '@/assets/images/projects/fives_pdp2-2.png'
import pwtsraLogoUrl from '@/assets/images/projects/pwtsra_logo.png'
import pwtsralogoLightUrl from '@/assets/images/projects/pwtsra_logo_light.png'
import pwtsraReactionsUrl from '@/assets/images/projects/pwtsra_reactions.png'
import ausyLogoUrl from '@/assets/images/projects/ausy_logo.png'
import ausyLogoLightUrl from '@/assets/images/projects/ausy_logo_light.png'
import codustelLogoUrl from '@/assets/images/projects/codustel_logo.png'
import codustelLogo2Url from '@/assets/images/projects/codustel_logo_2.png'
import codustelGraasUrl from '@/assets/images/projects/codustel_graas.jpg'
import welcomniLogoUrl from '@/assets/images/projects/welcomni_logo.png'
import welcomniLogoLightUrl from '@/assets/images/projects/welcomni_logo_light.png'
import limitlessWelcomniUrl from '@/assets/images/projects/limitless_welcomni.jpg'
import fivesQop1Url from '@/assets/images/projects/fives_qop1.png'
import fivesQop2Url from '@/assets/images/projects/fives_qop2.png'
import fivesSupplyChainUrl from '@/assets/images/projects/fives_supply-chain.png'
import fivesDrawingUrl from '@/assets/images/projects/fives_drawing.png'
import limithraLogoUrl from '@/assets/images/projects/li-mithra_logo.png'
import limithraHomepageUrl from '@/assets/images/projects/li-mithra_homepage.png'
import pixadLogoUrl from '@/assets/images/projects/pixad_logo.png'
import paypalLogoUrl from '@/assets/images/projects/paypal_logo.png'
import paypalFormUrl from '@/assets/images/projects/paypal_form.png'

export default {
	tagTitle: 'Quelques projets avec Symfony et Vue.js',
	metaDescription: 'Portfolio de mes développements web et mobile.',
	metaOgType: 'article',
	projects: [
		{
			year: '2023',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: telusLogoUrl,
			text:
				'Retour à Pomelo peu de temps après son acquisition par Telus Santé. ' +
				"Telus Santé fournie des solutions de télémédecine, de gestion de dossiers médicaux électroniques et d'assurance-maladie. L'objectif est de simplifier les soins de santé pour les patients et professionnels de la santé. " +
				'Rebranding, refactoring, mise en place de tests E2E et monitoring avec Sentry.',
			stack:
				'Vue.js, Laravel 5, Node.js 14, PHP 7.4, MariaDB 12, Sentry, Docker',
			link: 'https://www.telus.com/fr/health',
		},
		{
			year: '2022',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: actusiteLogoUrl,
			text: "Développement de nouvelles fonctionnalités pour la plateforme d'Actusite qui accompagne les professions réglementées (avocats, notaires, courtiers, ...) en proposant un site web personnalisable, un fil d'actualité, des newsletters, des réseaux sociaux et un référencement Google. Intégrations multiples d'API, création de commandes de console, de tableaux de bord et d'un backoffice pour gérer la publication manuelle et automatisée des articles.",
			stack:
				'Symfony, Messenger, HttpClient, ES6+, Encore Webpack, Chart.js, Azure, Docker',
			link: 'https://www.actusite.fr/',
		},
		{
			year: '2021',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: vigilanceLogoUrl,
			text: "Au sein d'une équipe Agile basée en Amérique du Nord - développement d'outils informatiques et de banques de données pour les professionnels de la santé. Informations sur les médicaments et la santé pour les cliniciens et leurs patients.",
			stack: 'TypeScript, Vue.js, PHP 7.4, Symfony 4, Node.js 14',
			link: 'https://www.vigilance.ca/',
		},
		{
			year: '2021',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: pomeloLogoUrl,
			text: "Au sein d'une équipe Agile basée en Amérique du Nord - développement d'outils comme la réservation en ligne, les diffusions de masse, les rendez-vous vidéo ainsi qu'un système de gestion de file d'attente conçu pour les soins urgents.",
			stack: 'Vue.js MPA, PHP 7.2, Laravel 5, MariaDB 10',
			link: 'https://www.pomelohealth.com/',
		},
		{
			year: '2021',
			title: 'Fives Intralogistics',
			text: 'Freelance à temps partiel : développement d’une application de gestion de pointage.',
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPointageUrl,
			imageAlt: 'fives pointage',
			link: 'https://intralogistics.fivesgroup.com/fr/qui-sommes-nous/qui-sommes-nous.html',
		},
		{
			year: '2020',
			title: 'PWTSRA',
			text: "Développement web à titre de formation personnelle à React. Application d'entrainement au Wing Chun, art martial traditionnel chinois rendu célèbre notamment par Ip Man.",
			stack: 'React, Hooks, Material UI',
			logo: pwtsraLogoUrl,
			logoLight: pwtsralogoLightUrl,
			logoAlt: 'pwtsra logo',
			image: pwtsraReactionsUrl,
			imageAlt: 'pstsra reactions de bases des mains',
			link: 'https://pwts.creativem.fr',
		},
		{
			year: '2020',
			title: 'Ausy',
			text: 'TMA et Évolutions de la plateforme Internet et Intranet de CLADE, base de données bibliographique et documentaire électronique du ministère des Armées.',
			stack: 'ES6+, Drupal, MySQL',
			logo: ausyLogoUrl,
			logoLight: ausyLogoLightUrl,
			logoAlt: 'ausy logo',
			link: 'https://www.ausy.fr/fr',
		},
		{
			year: '2020',
			title: 'Fives Intralogistics',
			text: "Reprise du Planning de Production pour rendre possible l'importation de données hiérarchiques multi-niveaux (arbre). Migration vers l'API Rest interne & Application PWA desktop & mobile.",
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12, Tree - Nestedset extension for Doctrine 2',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPdp21Url,
			imageAlt: 'fives pdp 2-1',
			image2: fivesPdp22Url,
			imageAlt2: 'fives pdp 2-2',
			link: '',
		},
		{
			year: '2019',
			title: 'GraceSaas',
			text: "Freelance à temps partiel, en renfort pour une agence Web : développement d'une application destinée aux administrateurs de données pour la modélisation de réseaux de télécommunications selon le standard national pour l'Aménagement Numérique des Territoires. Front-end JS et PHP API Rest.",
			stack: 'Vue.js, LeafletJs, PHP 7, Symfony 4, API Platform',
			logo: codustelLogo2Url,
			logoAlt: 'codustel logo',
			image: codustelGraasUrl,
			imageAlt: 'codultel graas',
			link: 'https://codustel.fr/',
		},
		{
			year: '2019',
			title: 'Welcomni',
			text: 'Freelance à temps partiel, en renfort pour une agence Web : développement de la plateforme de tourisme intéractive et 360° par drone, Welcomni. Front-end JS et PHP API Rest.',
			stack: 'Vue.js SPA, PHP 7, Symfony 4, API Platform',
			logo: welcomniLogoUrl,
			logoLight: welcomniLogoLightUrl,
			logoAlt: 'welcomni logo',
			image: limitlessWelcomniUrl,
			imageAlt: 'limitless welcomni',
			link: 'https://www.welcomni.com/',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: "Développement d'un outil de gestion des non conformités & plans d’actions correctives dans une démarche d’amélioration continue. Application PWA desktop & mobile, API REST.",
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesQop1Url,
			imageAlt: 'fives qop-1',
			image1: fivesQop2Url,
			imageAlt1: 'fives qop-2',
			link: '',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: 'Développement du Planning de Production utilisé par les services Planning, Approvisionnement, Montage, Préparation et Expédition.',
			stack: 'Vue.js SPA, PHP 7, Symfony 4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPdpUrl,
			imageAlt: 'fives pdp 1',
			link: '',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: "Développement d'un outil de suivi des approvisionnements remplaçant avantageusement les extracts SAP. Fonctions de recherche par code d'article, d'alerte email et extraction Excel intégrées.",
			stack: 'Vue.js SPA, PHP 7, Symfony 4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesSupplyChainUrl,
			imageAlt: 'fives supplychain',
			link: '',
		},
		{
			year: '2018',
			title: 'Fives Intralogistics',
			text: "Développement d'un outil de génération de plans techniques par lots, avec suivi et journal des modifications pour une gamme de convoyeurs du bureau d'études mécaniques.",
			stack: 'Vue.js, PHP 7, migration de Symfony 2 vers Sf4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesDrawingUrl,
			imageAlt: 'fives drawing',
			link: '',
		},
		{
			year: '2018',
			title: 'Li-Mithra',
			text: "Développement d'un site web et de son back office pour la publication d'actualités, de photos et de descriptions de chantiers.",
			stack:
				'jQuery, Bootstrap 4, FontAwesome 5, PHP 7, Symfony 4, MySQL 5.7, Google Analytics, HotJar',
			logo: limithraLogoUrl,
			logoAlt: 'li-mithra logo',
			image: limithraHomepageUrl,
			imageAlt: 'li-mithra homepage',
			link: 'https://www.li-mithra.fr/fr',
		},
		{
			year: '2018',
			title: 'API PayPal Developer.',
			text: "Développement d'une web app pour la réservation et le paiement en ligne pour un gite. API PayPal Developer.",
			stack: 'PHP 7, MySQL 5.7',
			logo: paypalLogoUrl,
			logoAlt: 'paypal logo',
			image: paypalFormUrl,
			imageAlt: 'paypal form',
			link: 'https://developer.paypal.com/',
		},
		{
			year: '2018',
			title: 'Pixad',
			text: "Participation au développement d'une web app de guidage vocal pour un entrepôt de stockage d'une boutique en ligne : détermination du chemin le plus court.",
			stack: 'PHP 5.6, MySQL 5.7',
			logo: pixadLogoUrl,
			logoAlt: 'pixad logo',
			link: 'https://www.pixad.fr/index.php',
		},
		{
			year: '2017',
			title: 'Pixad',
			text: "Intégration du script quagga.js pour apporter la fonctionnalité de lecture de code barre EAN au sein d'une web app sous Framework7 (JS).",
			stack: 'Vanilla JavaScript, PHP 5.6, MySQL 5.7',
			logo: pixadLogoUrl,
			logoAlt: 'pixad logo',
			link: 'https://www.pixad.fr/index.php',
		},
	],
}
