import { createI18n } from 'vue-i18n'

import generalFr from './fr.general'
import homeFr from './fr.home'
import projectsFr from './fr.projects'
import resumeFr from './fr.resume'
import cheatsheetFr from './fr.cheatsheet'
import contactFr from './fr.contact'

import generalEn from './en.general'
import homeEn from './en.home'
import projectsEn from './en.projects'
import resumeEn from './en.resume'
import cheatsheetEn from './en.cheatsheet'
import contactEn from './en.contact'

const messages = {
	fr: {
		general: generalFr,
		home: homeFr,
		projects: projectsFr,
		resume: resumeFr,
		cheatsheet: cheatsheetFr,
		contact: contactFr,
	},
	en: {
		general: generalEn,
		home: homeEn,
		projects: projectsEn,
		resume: resumeEn,
		cheatsheet: cheatsheetEn,
		contact: contactEn,
	},
}

export default createI18n({
	locale: 'fr',
	fallbackLocale: 'fr',
	allowComposition: true, // you need to specify that!
	messages,
})
