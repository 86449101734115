<template>
	<v-bottom-navigation
		:model-value="currentBtn"
		class="overflow-x-auto"
		color="#81b2d8"
		dark
		fixed
		app
		grow
		mandatory
	>
		<v-btn
			value="home"
			:text="$t('general.btnHome')"
			:prepend-icon="mdiHome"
			@click="onClickBtn('home')"
		/>
		<v-btn
			value="projects"
			:text="$t('general.btnProjects')"
			:prepend-icon="mdiCodeTags"
			@click="onClickBtn('projects')"
		/>
		<v-btn
			value="resume"
			:text="$t('general.btnResume')"
			:prepend-icon="mdiBook"
			@click="onClickBtn('resume')"
		/>
		<v-btn
			value="cheatsheet"
			:text="mobile ? 'Tips' : $t('general.btnCheat')"
			:prepend-icon="mdiStar"
			@click="onClickBtn('cheatsheet')"
		/>
		<v-btn
			value="contact"
			:text="$t('general.btnContact')"
			:prepend-icon="mdiCardAccountDetails"
			@click="onClickBtn('contact')"
		/>
		<v-spacer></v-spacer>
		<v-menu>
			<template #activator="{ props }">
				<v-btn
					:text="$t('general.btnLanguage')"
					:prepend-icon="mdiWeb"
					v-bind="props"
				/>
			</template>
			<v-list>
				<v-list-item
					v-for="(language, i) in languages"
					:key="i"
					:value="language"
					@click="changeLocale(language.language)"
				>
					<template #prepend>
						<img
							:alt="language.url + '-flag'"
							:src="language.url"
							style="height: 16px"
							class="mr-2"
						/>
					</template>
					<v-list-item-title>{{ language.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<v-btn :icon="mdiThemeLightDark" @click="toggleTheme">
			<template #default>
				<v-icon
					size="large"
					:color="$vuetify.theme.current.dark ? 'yellow' : 'primary'"
				/>
			</template>
		</v-btn>
	</v-bottom-navigation>
</template>

<script setup>
import {
	mdiBook,
	mdiCardAccountDetails,
	mdiCodeTags,
	mdiHome,
	mdiStar,
	mdiThemeLightDark,
	mdiWeb,
} from '@mdi/js'
import gbFlagUrl from '@/assets/flags/gb.svg'
import frFlagUrl from '@/assets/flags/fr.svg'
import { ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useRoute, useRouter } from 'vue-router'

const { mobile } = useDisplay()
const theme = useTheme()
const route = useRoute()
const router = useRouter()

const languages = [
	{
		url: gbFlagUrl,
		language: 'en',
		title: 'English',
	},
	{
		url: frFlagUrl,
		language: 'fr',
		title: 'Français',
	},
]
const currentBtn = ref(null)

watch(route, () => (currentBtn.value = route.name), { deep: true })

const onClickBtn = routeName => router.push({ name: routeName }).catch(() => {})
const changeLocale = locale => {
	router.push({ name: route.name, params: { lang: locale } }).catch(() => {})
}
const toggleTheme = () =>
	(theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark')
</script>

<style lang="css">
.v-bottom-navigation .v-bottom-navigation__content {
	/* required so first btn is visible when overflow-x */
	justify-content: normal;
}
</style>
