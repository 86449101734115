import frFlagUrl from '@/assets/flags/fr.svg'
import arFlagUrl from '@/assets/flags/ar.svg'
import vnFlagUrl from '@/assets/flags/vn.svg'
import myFlagUrl from '@/assets/flags/my.svg'

export default {
	pdf: '/pdf/cv-michael-dieudonne-fr.pdf',
	title: 'Michaël Dieudonné',
	position1: 'Développeur web full-stack JavaScript & PHP',
	position2: '',
	tagTitle: 'CV développeur Vue.js',
	metaDescription:
		'CV de Michael Dieudonné, développeur Symfony, Vue.js et API Platform.',
	metaOgType: 'article',
	goals: {
		title: 'Evolution souhaitée',
		content:
			'Travailler sur des projets d’ampleurs aux besoins spécifiques (développement).',
		content1:
			'Continuer à approfondir ma maitrise de <strong>JS/TS</strong> et architecture Web/logicielle.',
		content2:
			'Monter en compétence sur Node.js, Vue.js, React.js et TypeScript.',
	},
	skills: {
		title: 'Compétences Techniques',
		content: [
			{
				title: 'LANGAGES',
				content: 'ES6+, PHP, Twig, HTML, CSS',
			},
			{
				title: 'FRAMEWORKS, CMS & APIs',
				content: 'Vue.js, React.js',
				content1: 'Symfony, Drupal',
				content2: 'API Platform',
			},
			{
				title: 'SERVEURS APPLICATIFS / WEB',
				content: 'Apache, Nginx (native & Docker)',
			},
			{ title: 'SGBD', content: 'MySQL, MariaDB, PostgreSQL' },
			{
				title: 'TESTS',
				content: 'Jest, PHPUnit',
			},
			{
				title: 'GESTION DE VERSION',
				content: 'Git (GitHub, BitBucket), GitFlow',
			},
			{
				title: 'CI/CD',
				content: 'BitBucket Pipelines, AWS CodePipeline',
			},
			{
				title: 'METHODOLOGIE',
				content: 'Scrum process, Sprint planning, Code|Pair Review, JIRA',
				content1: 'Ingénieur projet dans l’industrie (7 ans)',
				content2: 'R&D, Méthodes, Qualité, Production',
			},
			{
				title: 'SECURITÉ',
				content: 'JWT, LDAP, Salt & Password Hash',
				content1: 'Gestion des Rôles, Routes & Accès',
			},
			{
				title: 'SYSTEMES',
				content: 'Linux (Ubuntu, CentOS), Windows 10 WSL 1/ 2',
			},
			{ title: 'CAO', content: 'SolidWorks, Inventor, Catia V5' },
			{
				title: 'VEILLE',
				content: 'TypeScript, Node.js, Docker & Kubernetes',
				content1: 'css-for-js',
				content2: 'Symfony Blog, State Of JS / CSS',
			},
		],
	},
	experiences: {
		title: 'Expériences Professionnelles',
		content: [
			{
				title: 'abbeal, Lyon, France',
				content:
					'Développeur JavaScript, <strong>février 2021 à ce jour</strong>',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'AUSY, Lyon, France',
				content: 'Développeur Web, août 2020 à janvier 2021',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'CreativeM, microentreprise',
				content:
					'Développeur Web Freelance, <strong>juillet 2019 à ce jour</strong>',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'EFOR, Strasbourg, France',
				content: 'Développeur Web, juillet 2018 à juin 2020',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'EAS, France',
				content: 'Ingénieur projet Machines Spéciales, 2017',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'ENGIE, Nouvelle-Calédonie',
				content: "Ingénieur chargé d'affaires énergies renouvelables, 2016",
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'SODEX-SPORT, Vietnam',
				content: "Resp. bureau d'études & amélioration continue, 2014",
				country: 'vn',
				flag: vnFlagUrl,
			},
			{
				title: 'SIMONE-PERELE, Vietnam',
				content: 'Responsable de production, 2011',
				country: 'vn',
				flag: vnFlagUrl,
			},
			{
				title: 'STREAM-ENVIRONMENT, Malaisie',
				content: 'Ingénieur R&D, 2010',
				country: 'my',
				flag: myFlagUrl,
			},
			{
				title: 'RADIADORES DON BOSCO, Argentine',
				content: 'Echange universitaire, 2009',
				country: 'ar',
				flag: arFlagUrl,
			},
			{
				title: 'PSA PEUGEOT-CITROEN, France',
				content: 'Stage Ingénieur méthodes et suivi Production, 2008',
				country: 'fr',
				flag: frFlagUrl,
			},
		],
	},
	formations: {
		title: 'Formations',
		content: [
			{
				title: '2018 Quai Alpha',
				link: 'https://www.quai-alpha.com/',
				content: 'Titre pro. développeur logiciel',
				extra: true,
			},
			{
				title: '2010 UTBM',
				link: 'https://www.utbm.fr/',
				content: "Diplôme d'ingénieur en développement de produits",
			},
			{
				title: '2007 IUT Nancy Brabois ',
				link: 'http://iutnb.univ-lorraine.fr/',
				content: 'DUT mécanique et productique',
			},
		],
	},
	languages: {
		title: 'Langues',
		content: [
			{ title: 'Anglais courant', content: '860/990 TOEIC 2010' },
			{
				title: 'Espagnol intermédiaire',
				content: 'intermedio bueno CELU 2009',
			},
		],
	},
}
