export default {
	language: 'English',
	flag: 'en',
	btnHome: 'Home',
	btnProjects: 'Projects',
	btnResume: 'Resume',
	btnCheat: 'Cheat Sheets',
	btnContact: 'Contact',
	btnLanguage: 'Language',
}
