import telusLogoUrl from '@/assets/images/projects/telus_logo.png'
import actusiteLogoUrl from '@/assets/images/projects/actusite_logo.svg'
import vigilanceLogoUrl from '@/assets/images/projects/vigilance_logo.svg'
import pomeloLogoUrl from '@/assets/images/projects/pomelo_logo.png'
import fivesLogoUrl from '@/assets/images/projects/fives_logo.png'
import fivesPointageUrl from '@/assets/images/projects/fives_pointage.png'
import fivesPdpUrl from '@/assets/images/projects/fives_pdp.png'
import fivesPdp21Url from '@/assets/images/projects/fives_pdp2-1.png'
import fivesPdp22Url from '@/assets/images/projects/fives_pdp2-2.png'
import pwtsraLogoUrl from '@/assets/images/projects/pwtsra_logo.png'
import pwtsralogoLightUrl from '@/assets/images/projects/pwtsra_logo_light.png'
import pwtsraReactionsUrl from '@/assets/images/projects/pwtsra_reactions.png'
import ausyLogoUrl from '@/assets/images/projects/ausy_logo.png'
import ausyLogoLightUrl from '@/assets/images/projects/ausy_logo_light.png'
import codustelLogoUrl from '@/assets/images/projects/codustel_logo.png'
import codustelLogo2Url from '@/assets/images/projects/codustel_logo_2.png'
import codustelGraasUrl from '@/assets/images/projects/codustel_graas.jpg'
import welcomniLogoUrl from '@/assets/images/projects/welcomni_logo.png'
import welcomniLogoLightUrl from '@/assets/images/projects/welcomni_logo_light.png'
import limitlessWelcomniUrl from '@/assets/images/projects/limitless_welcomni.jpg'
import fivesQop1Url from '@/assets/images/projects/fives_qop1.png'
import fivesQop2Url from '@/assets/images/projects/fives_qop2.png'
import fivesSupplyChainUrl from '@/assets/images/projects/fives_supply-chain.png'
import fivesDrawingUrl from '@/assets/images/projects/fives_drawing.png'
import limithraLogoUrl from '@/assets/images/projects/li-mithra_logo.png'
import limithraHomepageUrl from '@/assets/images/projects/li-mithra_homepage.png'
import pixadLogoUrl from '@/assets/images/projects/pixad_logo.png'
import paypalLogoUrl from '@/assets/images/projects/paypal_logo.png'
import paypalFormUrl from '@/assets/images/projects/paypal_form.png'

export default {
	tagTitle: 'Some projects made with Symfony and Vue.js',
	metaDescription:
		'List and description of my professional projects in web & mobile.',
	metaOgType: 'article',
	projects: [
		{
			year: '2023',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: telusLogoUrl,
			text:
				'Returned to Pomelo shortly after its acquisition by Telus Health. Telus Health provides telemedicine, electronic medical record management, and health insurance solutions. ' +
				'The goal is to simplify healthcare for patients and healthcare professionals. ' +
				'Rebranding, refactoring, implementation of E2E tests, and monitoring with Sentry.',
			stack:
				'Vue.js, Laravel 5, Node.js 14, PHP 7.4, MariaDB 12, Sentry, Docker',
			link: 'https://www.telus.com/en/health',
		},
		{
			year: '2022',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: actusiteLogoUrl,
			text: 'Development of new features for the Actusite platform which supports regulated professions (lawyers, notaries, brokers, etc.) by offering a customizable website, a news feed, newsletters, social networks and Google referencing. Multiple API integrations, creation of console commands, dashboards and backoffice to manage manual and automated posting of articles.',
			stack:
				'Symfony, Messenger, HttpClient, ES6+, Encore Webpack, Chart.js, Azure, Docker',
			link: 'https://www.actusite.fr/',
		},
		{
			year: '2021',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: vigilanceLogoUrl,
			text: 'Working remotely within an Agile development team based in North America - development of IT tools and databases for healthcare professionals. Drug and health information for clinicians and their patients.',
			stack: 'TypeScript, Vue.js, PHP 7.4, Symfony 4, Node.js 14',
			link: 'https://www.vigilance.ca/',
		},
		{
			year: '2021',
			title: '<a href="https://www.abbeal.com/" target="_blank">abbeal</a>',
			class: 'abbeal',
			logo: pomeloLogoUrl,
			text: 'Working remotely within an Agile development team based in North America - development of tools such as online booking, mass broadcasts, video appointments as well as a queue management system designed for urgent care.',
			stack: 'Vue.js MPA, Laravel 5, Node.js 14, PHP 7.2, MariaDB 10',
			link: 'https://www.pomelohealth.com/',
		},
		{
			year: '2021',
			title: 'Fives Intralogistics',
			text: 'Part-time freelance : development of a timesheet management application.',
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPointageUrl,
			imageAlt: 'fives clocking',
			link: 'https://intralogistics.fivesgroup.com/fr/qui-sommes-nous/qui-sommes-nous.html',
		},
		{
			year: '2020',
			title: 'PWTSRA',
			text: 'Web development as personal training to React which consist in a Wing Chun training application, a traditional Chinese martial art made famous by Ip Man.',
			stack: 'React, Hooks, Material UI',
			logo: pwtsraLogoUrl,
			logoLight: pwtsralogoLightUrl,
			logoAlt: 'pwtsra logo',
			image: pwtsraReactionsUrl,
			imageAlt: 'pstsra basic hands reactions',
			link: 'https://pwts.creativem.fr',
		},
		{
			year: '2020',
			title: 'Ausy',
			text: 'AM and Evolutions of the CLADE platform, electronic bibliographic and documentary database of the Ministry of the Armed Forces.',
			stack: 'ES6+, Drupal, MySQL',
			logo: ausyLogoUrl,
			logoLight: ausyLogoLightUrl,
			logoAlt: 'ausy logo',
			link: 'https://www.ausy.fr/en',
		},
		{
			year: '2020',
			title: 'Fives Intralogistics',
			text: 'Update of the Production Planning to make the import of multi-level data possible (nested tree). Migration to the company Rest API & a PWA desktop & mobile application.',
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12, Tree - Nestedset extension for Doctrine 2',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPdp21Url,
			imageAlt: 'fives pdp 2-1',
			image2: fivesPdp22Url,
			imageAlt2: 'fives pdp 2-2',
			link: '',
		},
		{
			year: '2019',
			title: 'GraceSaas',
			text: 'Part-time freelance, in reinforcement for a Web agency : development of an application intended for the administrators of data for the modeling of telecommunications networks according to the national standard for the digital development of the territories. Front-end JS & PHP Rest API.',
			stack: 'Vue.js, LeafletJs, PHP 7, Symfony 4, API Platform',
			logo: codustelLogo2Url,
			logoAlt: 'codustel logo',
			image: codustelGraasUrl,
			imageAlt: 'codultel graas',
			link: 'https://codustel.fr/',
		},
		{
			year: '2019',
			title: 'Welcomni',
			text: 'Part-time freelance, in reinforcement for a Web agency : development of the interactive and 360 ° touring platform by drone, Welcomni. Front-end JS & PHP Rest API.',
			stack: 'Vue.js SPA, PHP 7, Symfony 4, API Platform',
			logo: welcomniLogoUrl,
			logoLight: welcomniLogoLightUrl,
			logoAlt: 'welcomni logo',
			image: limitlessWelcomniUrl,
			imageAlt: 'limitless welcomni',
			link: 'https://www.welcomni.com/',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: 'Development of a nonconformity management tool & corrective action plans in a continuous improvement process. PWA desktop & mobile application, REST API.',
			stack:
				'Vue.js SPA, Vuetify, PHP 7.4, Symfony 5, API Platform, PostgreSQL 12',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesQop1Url,
			imageAlt: 'fives qop-1',
			image1: fivesQop2Url,
			imageAlt1: 'fives qop-2',
			link: '',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: 'Development of the Production Planning used by the planning, supply chain, assembly, preparation and shipping departments.',
			stack: 'Vue.js SPA, PHP 7, Symfony 4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesPdpUrl,
			imageAlt: 'fives pdp 1',
			link: '',
		},
		{
			year: '2019',
			title: 'Fives Intralogistics',
			text: 'Development of a supply tracking tool that advantageously replaces SAP extracts. Many built-in features as search by article code, email alert and Excel extraction.',
			stack: 'Vue.js SPA, PHP 7, Symfony 4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesSupplyChainUrl,
			imageAlt: 'fives supplychain',
			link: '',
		},
		{
			year: '2018',
			title: 'Fives Intralogistics',
			text: 'Development of a tool to generate drawings batches with revision tracking and changelog  for a range of conveyors for the Mechanical Engineering Office.',
			stack: 'Vue.js, PHP 7, migration from Symfony 2 to Sf4, MySQL 5.7',
			logo: fivesLogoUrl,
			logoAlt: 'fives logo',
			image: fivesDrawingUrl,
			imageAlt: 'fives drawing',
			link: '',
		},
		{
			year: '2018',
			title: 'Li-Mithra',
			text: 'Development of a website and its back office for the publication of news, photos and construction site descriptions.',
			stack:
				'jQuery, Bootstrap 4, FontAwesome 5, PHP 7, Symfony 4, MySQL 5.7, Google Analytics, HotJar',
			logo: limithraLogoUrl,
			logoAlt: 'li-mithra logo',
			image: limithraHomepageUrl,
			imageAlt: 'li-mithra homepage',
			link: 'https://www.li-mithra.fr/en',
		},
		{
			year: '2018',
			title: 'API PayPal Developer.',
			text: 'Development of a web app for online booking and payment for a cottage. PayPal API Developer.',
			stack: 'PHP 7, MySQL 5.7',
			logo: paypalLogoUrl,
			logoAlt: 'paypal logo',
			image: paypalFormUrl,
			imageAlt: 'paypal form',
			link: 'https://developer.paypal.com',
		},
		{
			year: '2018',
			title: 'Pixad',
			text: 'Participation in the development of a voice guidance web app for an online store warehouse: determination of the shortest path.',
			stack: 'PHP 5.6, MySQL 5.7',
			logo: pixadLogoUrl,
			logoAlt: 'pixad logo',
			link: 'https://www.pixad.fr/index.php',
		},
		{
			year: '2017',
			title: 'Pixad',
			text: 'Integration of the quagga.js script to integrate EAN barcode reading into a web app under Framework7 (JS).',
			stack: 'Vanilla JavaScript, PHP 5.6, MySQL 5.7',
			logo: pixadLogoUrl,
			logoAlt: 'pixad logo',
			link: 'https://www.pixad.fr/index.php',
		},
	],
}
