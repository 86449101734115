import frFlagUrl from '@/assets/flags/fr.svg'
import arFlagUrl from '@/assets/flags/ar.svg'
import vnFlagUrl from '@/assets/flags/vn.svg'
import myFlagUrl from '@/assets/flags/my.svg'

export default {
	pdf: '/pdf/cv-michael-dieudonne-en.pdf',
	title: 'Michaël Dieudonné',
	position1: 'Full-stack web developer JavaScript & PHP',
	position2: '',
	tagTitle: 'CV web developer Vue.js',
	metaDescription:
		'CV of Michael Dieudonne, developer Vue.js, Symfony & API Platform.',
	metaOgType: 'article',
	goals: {
		title: 'Career goals',
		content: 'Work on major projects with specific needs (custom development).',
		content1:
			'Go deeper with <strong>JS/TS</strong> and software architecture.',
		content2:
			'Learn and build skills on Node.js, Vue.js, React.js and TypeScript.',
	},
	skills: {
		title: 'Technical skills',
		content: [
			{
				title: 'LANGUAGES',
				content: 'ES6+, PHP, Twig, HTML, CSS',
			},
			{
				title: 'FRAMEWORKS, CMS & APIs',
				content: 'Vue.js, React.js',
				content1: 'Symfony, Drupal',
				content2: 'API Platform',
			},
			{
				title: 'WEB SERVERS',
				content: 'Apache, Nginx (native & Docker)',
			},
			{
				title: 'DBMS',
				content: 'MySQL, MariaDB, PostgreSQL',
			},
			{ title: 'TESTS', content: 'Jest, PHPUnit' },
			{
				title: 'VERSIONING',
				content: 'Git (GitHub, BitBucket), GitFlow',
			},
			{ title: 'CI/CD', content: 'BitBucket Pipelines, AWS CodePipeline' },
			{
				title: 'METHODOLOGY',
				content: 'Scrum process, Sprint planning, Code|Pair Review, JIRA',
				content1: '7 years of engineering in industrial projects',
				content2: 'R&D, Quality, Industrialization & Production',
			},
			{
				title: 'SECURITY',
				content: 'JWT, LDAP, Salt & Password Hash',
				content1: 'Role Management, Routes & Access',
			},
			{ title: 'OS', content: 'Linux (Ubuntu, CentOS), Windows 10 WSL 1/2' },
			{ title: 'CAD', content: 'SolidWorks, Inventor, Catia V5' },
			{
				title: 'TECHNOLOGY WATCH',
				content: 'TypeScript, Node.js, Docker & Kubernetes',
				content1: 'css-for-js',
				content2: 'Symfony Blog, State Of JS / CSS',
			},
		],
	},
	experiences: {
		title: 'Work history',
		content: [
			{
				title: 'abbeal, Lyon, France',
				content: 'Developer JavaScript, <strong>February 2021 to date</strong>',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'AUSY, Lyon, France',
				content: 'Web developer, Aug. 2020 to Jan. 2021',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'CreativeM, microenterprise creation',
				content: 'Web developer Freelance, <strong>July 2019 to date</strong>',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'EFOR, Strasbourg, France',
				content: 'Web developer, Jul. 2018 to Jun. 2020',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'EAS, France',
				content: 'Special Machines Project Engineer, 2017',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'ENGIE, New-Caledonia',
				content: 'Engineer in charge of renewable energy business, 2016',
				country: 'fr',
				flag: frFlagUrl,
			},
			{
				title: 'SODEX-SPORT, Vietnam',
				content: 'Head of design office & continuous improvement, 2014',
				country: 'vn',
				flag: vnFlagUrl,
			},
			{
				title: 'SIMONE-PERELE, Vietnam',
				content: 'Production & Quality manager, 2011',
				country: 'vn',
				flag: vnFlagUrl,
			},
			{
				title: 'STREAM-ENVIRONMENT, Malaysia',
				content: 'R&D engineer, 2010',
				country: 'my',
				flag: myFlagUrl,
			},
			{
				title: 'RADIADORES DON BOSCO, Argentina',
				content: 'University exchange, 2009',
				country: 'ar',
				flag: arFlagUrl,
			},
			{
				title: 'PSA PEUGEOT-CITROEN, France',
				content: 'Internship Methods Engineer & Production Monitoring, 2008',
				country: 'fr',
				flag: frFlagUrl,
			},
		],
	},
	formations: {
		title: 'Training',
		content: [
			{
				title: '2018 Quai Alpha',
				link: 'https://www.quai-alpha.com/',
				content: 'Associate Degree in Software Engineering',
				extra: true,
			},
			{
				title: '2010 UTBM',
				link: 'https://www.utbm.fr/english/',
				content: 'Product Design Engineer',
			},
			{
				title: '2007 IUT Nancy Brabois ',
				link: 'http://iutnb.univ-lorraine.fr/en/',
				content: 'Associate Degree in Mechanical & Production',
			},
		],
	},
	languages: {
		title: 'Languages',
		content: [
			{ title: 'Fluent English', content: '860/990 TOEIC, 2010' },
			{
				title: 'Intermediate Spanish',
				content: 'intermedio bueno CELU, 2009',
			},
		],
	},
}
