export default {
	language: 'Français',
	flag: 'fr',
	btnHome: 'Accueil',
	btnProjects: 'Projets',
	btnResume: 'C.V.',
	btnCheat: 'Cheat Sheets',
	btnContact: 'Contact',
	btnLanguage: 'Langue',
}
