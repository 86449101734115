<template>
	<v-app>
		<v-main>
			<router-view v-slot="{ Component }">
				<transition name="router-anim" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</v-main>
		<bottom-nav />
	</v-app>
</template>
<script setup>
import BottomNav from './components/BottomNav.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const swipe = direction => router.push({ name: route.meta[direction] })
</script>

<style lang="css">
html {
	overflow-y: auto !important;
}

.router-anim-enter-active {
	animation: coming 0.2s;
	/*
                animation-delay: .2s;
        */
	opacity: 0;
}

.router-anim-leave-active {
	animation: going 0.4s;
}

@keyframes going {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-100px);
		opacity: 0;
	}
}

@keyframes coming {
	from {
		transform: translateY(-100px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}
</style>
